<script>
/* eslint-env browser */
import { fade } from 'svelte/transition'
import { onMount } from 'svelte'

class Zoomable {

  constructor() {
    this.imagerect = undefined
  }

  /**
   *
   * @returns {void}
   */
  observeThumbnails() {
    const options = {
      root: thumbs,
      rootMargin: '0px',
      threshold: 0.5
    }

    this.observer = new IntersectionObserver(this.handleThumbIntersection, options);
    this.observer.observe(thumbs.querySelector('li:first-child'))
    this.observer.observe(thumbs.querySelector('li:last-child'))
  }

  handleThumbIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.target === thumbs.querySelector('li:first-child')) {
        showThumbScrollL = !(entry.isIntersecting)
      }
      if (entry.target === thumbs.querySelector('li:last-child')) {
        showThumbScrollR = !(entry.isIntersecting)
      }
    })
  }

  /**
   * @param {Event} event
   *
   * @returns {void}
   */
  event_startZoom(event) {
    this.imagerect = event.target.getBoundingClientRect()
    this.moveZoomBox(event)

    show_zoom = true
  }

  /**
   * @param {TouchEvent} event
   *
   * @returns {void}
   */
  event_startZoomTouch(event) {
    if (event.touches.length === 1) {
      event.preventDefault()
      this.event_startZoom(event);
    }
  }

  /**
   * @param {Event} event
   *
   * @returns {void}
   */
  event_moveZoom(event) {
    if (!show_zoom) return
    event.preventDefault()
    this.moveZoomBox(event)
  }

  /**
   * Moves the zoom box
   *
   * @param {Event} event
   * @returns {undefined}
   */
  moveZoomBox(event) {
    let posX
    let posY

    if (event.type === 'touchmove') {
      posX = event.touches[0].clientX
      posY = event.touches[0].clientY
    } else {
      posX = event.clientX
      posY = event.clientY
    }

    if (posX < this.imagerect.left || posX > this.imagerect.right || posY < this.imagerect.top || posY > this.imagerect.bottom) {
      show_zoom = false
	  return
    }

    this.positionZoomBox(posX,posY)
    this.postionBackground(posX, posY)
  }

  /**
   * Sets the position of the zoom box
   *
   * @param {integer} posX
   * @param {integer} posY
   *
   * @return {void}
   */
  positionZoomBox(posX, posY) {
    const left = posX - this.imagerect.left - 100
    const top  = posY - this.imagerect.top - 100

    zoombox.style.left = left + 'px'
    zoombox.style.top = top + 'px'
  }

  /**
   * Sets the position of the background
   *
   * @param {integer} posX
   * @param {integer} posY
   *
   * @return {void}
   */
  postionBackground(posX, posY) {
    const left = posX - this.imagerect.left
    const top  = posY - this.imagerect.top

    const pLeft = 100 * left / this.imagerect.width
    const pTop  = 100 * top / this.imagerect.height

    zoombox.style.backgroundPosition = pLeft + '% ' + pTop + '%'
  }

  /**
   * Clicking the arrows on the side of the main image
   *
   * @param {integer} new_active_thumb
   * @returns {void}
   */
  arrowClickForMainImage(new_active_thumb) {
    active_thumb = new_active_thumb
    thumbs.querySelectorAll('li')[new_active_thumb].scrollIntoView({behavior: 'smooth', block: 'nearest'})
  }
}

let zoomable = new Zoomable()

/**
 * @type Number
 */
let active_thumb = 0

/**
 * @type Boolean
 */
let show_zoom = false

/**
 * @type HTMLElement
 */
let zoombox

/**
 * @type HTMLElement
 */
let thumbs

/**
 * @type Boolean
 */
let showThumbScrollL = false

/**
 * @type Boolean
 */
let showThumbScrollR = false

//required props
/**
 * Array of the images [{thumb_path, image_path, large_path}, {thumb_path, image_path, large_path}]
 *
 * @type Array
 */
export let images

/**
 * @type String
 */
export let imagealt

onMount(() => {
  zoomable.observeThumbnails()
})


</script>
<div class="flex justify-around gap-4 mb-4 group">
	<div class="hidden sm:block self-center w-12 shrink-0">
{#if active_thumb > 0}
		<button class="text-5xl block cursor-pointer text-center transition-all duration-500 opacity-0 group-hover:opacity-100 -translate-x-8 group-hover:translate-x-0"
			  on:keydown="{e => {}}"
			  on:click="{ e => zoomable.arrowClickForMainImage(active_thumb - 1)}">&#8249;</button>
{/if}
	</div>
	<div class="relative aspect-square w-52 md:w-80 lg:w-96 shrink-0">
{#each images as image, i}
{#if i == active_thumb}
		<img class="absolute aspect-square w-full object-scale-down"
			 src="{image.image_path}"
			 alt="{imagealt}"
			 transition:fade
			 on:mouseenter|preventDefault="{e => zoomable.event_startZoom(e) }"
			 on:touchstart="{e => zoomable.event_startZoomTouch(e)}"
			 on:mousemove|preventDefault="{e => zoomable.event_moveZoom(e) }"
		/>
{/if}
{/each}

		<div class="absolute border border-brand rounded-full sm:rounded-lg w-48 aspect-square cursor-zoom-in zoom-zoombox"
			 role="img"
			 aria-label="Zoomed image"
			 bind:this={zoombox}
			 class:hidden={!show_zoom}
			 style:background-image="url({images[active_thumb].large_path})"
			 on:mousemove|preventDefault="{e => zoomable.event_moveZoom(e) }"
			 on:mouseleave|preventDefault="{e => show_zoom = false }"
			 on:touchmove="{e => zoomable.event_moveZoom(e) }">&nbsp;
		</div>
	</div>
	<div class="hidden sm:block self-center w-12 shrink-0">
{#if (active_thumb + 1) < images.length}
		<button class="text-5xl block cursor-pointer text-center transition-all duration-500 opacity-0 group-hover:opacity-100 translate-x-8 group-hover:translate-x-0"
			  on:keydown="{ e => {}}"
			  on:click="{ e => zoomable.arrowClickForMainImage(active_thumb + 1)}">&#8250;</button>
{/if}
	</div>
</div>


<div class="flex justify-around gap-4 mb-4 group">
	<div class="hidden sm:block shrink-0 self-center w-12 shrink-0">
{#if showThumbScrollL}
		<button class="text-5xl block cursor-pointer text-center transition-all duration-500 opacity-0 group-hover:opacity-100 -translate-x-8 group-hover:translate-x-0"
			  on:keydown="{e => {}}"
			  on:click="{ e => thumbs.scrollTo({left: thumbs.scrollLeft - thumbs.clientWidth, behavior: 'smooth'})}">&#8249;</button>
{/if}
	</div>
	<ul class="flex gap-6 p-4 overflow-x-auto snap-x snap-mandatory scroll-pl-1"
		bind:this={thumbs}
		>
{#each images as image, i}
		<li class="shrink-0 cursor-pointer outline rounded-md {i === active_thumb ? 'outline-brand' : 'outline-1 outline-black'} snap-start"
			>
			<button on:keydown="{e => {}}"
			        on:click="{ e => active_thumb = i}">
				<img class="w-20 aspect-square object-scale-down" src="{image.thumb_path}" alt="{imagealt} thumbnail {i}" draggable="false" />
			</button>
		</li>
{/each}
	</ul>
	<div class="hidden sm:block self-center w-12 shrink-0">
{#if showThumbScrollR}
		<button class="text-5xl block cursor-pointer text-center transition-all duration-500 opacity-0 group-hover:opacity-100 translate-x-8 group-hover:translate-x-0"
			  on:keydown="{e => {}}"
			  on:click="{ e => thumbs.scrollTo({left: thumbs.scrollLeft + thumbs.clientWidth, behavior: 'smooth'})}">&#8250;</button>
{/if}
	</div>
</div>

<style>
	.zoom-zoombox {
		background : #fff no-repeat 0px 0px;
		z-index: 2000;
	}
</style>